<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Tin reacts with nitric acid in a redox process to produce tin dioxide and nitrogen dioxide
        according to the following (unbalanced) equation.
      </p>

      <p class="mb-6 pl-10">
        <chemical-latex content="Sn(s) + HNO3(aq) -> SnO2(s) + NO2(g)" />
      </p>

      <p class="mb-4">a) Which element is <b>reduced</b> in the reaction?</p>

      <v-radio-group v-model="inputs.reducedAtom" class="pl-8" row :disabled="!allowEditing">
        <v-radio
          v-for="option in atomOptions"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-6">
        b) Its oxidation state changed from
        <v-select
          v-model="inputs.reducedOSI"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="oxidationStateItems"
          placeholder="Select"
        />
        to
        <v-select
          v-model="inputs.reducedOSF"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="oxidationStateItems"
          placeholder="Select"
        />
      </p>

      <p class="mb-4">c) Which element is <b>oxidized</b> in the reaction?</p>

      <v-radio-group v-model="inputs.oxidizedAtom" class="pl-8" row :disabled="!allowEditing">
        <v-radio
          v-for="option in atomOptions"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-6">
        d) Its oxidation state changed from
        <v-select
          v-model="inputs.oxidizedOSI"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="oxidationStateItems"
          placeholder="Select"
        />
        to
        <v-select
          v-model="inputs.oxidizedOSF"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="oxidationStateItems"
          placeholder="Select"
        />
      </p>

      <p class="mb-4">c) Balance the redox reaction in <b>acidic</b> conditions.</p>

      <chemical-notation-input
        v-model="inputs.redoxReaction"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question261',
  components: {
    ChemicalLatex,
    ChemicalNotationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        reducedAtom: null,
        reducedOSI: null,
        reducedOSF: null,
        oxidizedAtom: null,
        oxidizedOSI: null,
        oxidizedOSF: null,
        redoxReaction: null,
      },
      atomOptions: [
        {expression: '$\\text{Sn}\\hspace{0.5cm}$', value: 'sn'},
        {expression: '$\\text{H}\\hspace{0.5cm}$', value: 'h'},
        {expression: '$\\text{N}\\hspace{0.5cm}$', value: 'n'},
        {expression: '$\\text{O}$', value: 'o'},
      ],
      oxidationStateItems: [
        '+8',
        '+7',
        '+6',
        '+5',
        '+4',
        '+3',
        '+2',
        '+1',
        '0',
        '-1',
        '-2',
        '-3',
        '-4',
        '-5',
        '-6',
        '-7',
        '-8',
      ],
    };
  },
};
</script>
